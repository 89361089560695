import Logo from "../resources/login/Logo.png";
import SideImage from "../resources/login/4402947_182992.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Login = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
  }, []);
  return (
    <>
      <div className="flex h-screen">
        {/* Left Section - Form */}
        <div className="w-full lg:w-1/2 flex items-center justify-center p-8 bg-white">
          <div className="max-w-md w-full max-md:-mt-24">
            <div className="flex flex-col items-center w-full">
              <div className="w-full">
                <img src={Logo} alt="Logo" className="mb-6 w-32 h-24" />
                <h1 className="text-2xl mb-4">
                  <div>Welcome Back to </div>
                  <div>Decision Pulse AI</div>
                </h1>
                <form className="w-full mt-10">
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Email
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="email"
                      placeholder="your@email.com"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Password
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="password"
                      placeholder="********"
                    />
                  </div>
                  <div className="mb-6">
                    <Link to="/home">
                      <button
                        type="submit"
                        className="w-full bg-[#33A9C8] hover:bg-[#1f8696] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Log In
                      </button>
                    </Link>
                  </div>
                  <div className="flex justify-between text-sm">
                    <a href="#" className="hover:underline">
                      Forgot password?
                    </a>
                    <div className="flex items-center">
                      No Account?{" "}
                      <Link to="/signup">
                        <span className="font-semibold text-[#33A9C8] hover:underline flex items-center ml-1">
                          Sign Up
                        </span>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section - Image */}
        <div className="hidden lg:block w-1/2 h-full">
          <img
            src={SideImage}
            alt="Side Image"
            className="h-full w-full object-cover"
          />
        </div>
      </div>
    </>
  );
};
export default Login;
