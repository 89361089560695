import { Link } from "react-router-dom";
const Main = ({ heading, image }) => {
  console.log(heading);
  return (
    <>
      {/* <h1>HIIIHIHIH</h1> */}
      <Link
        className="w-full max-w-[350px] sm:max-w-[378px] md:max-w-[536px]  xl:max-w-[536px] h-[256px] max-md:h-[165px] flex p-4 m-5 border rounded-3xl bg-white"
        style={{ boxShadow: "0 5px 1px #191A23" }}
        to={heading.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, "-")}
      >
        <div className="w-full flex">
          <div className="w-1/2 flex flex-col justify-between h-full md:py-5">
            <div className="inline-block text-white p-1">
              <p className="bg-[#006064] p-1 text-2xl max-md:text-base rounded-md">{heading}</p>
            </div>

            <div className="flex-grow"></div>

            <div className="flex items-center mb-2">
              <div className=" p-2 md:p-3 rounded-full bg-[#006064]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="17"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M1.25 13.701C0.532561 14.1152 0.286748 15.0326 0.700962 15.75C1.11518 16.4674 2.03256 16.7133 2.75 16.299L1.25 13.701ZM20.7694 5.38823C20.9838 4.58803 20.5089 3.76552 19.7087 3.55111L6.66874 0.0570543C5.86854 -0.157358 5.04603 0.317515 4.83162 1.11771C4.61721 1.91791 5.09208 2.74042 5.89228 2.95483L17.4834 6.06066L14.3776 17.6518C14.1631 18.452 14.638 19.2745 15.4382 19.4889C16.2384 19.7033 17.0609 19.2284 17.2753 18.4282L20.7694 5.38823ZM2.75 16.299L20.0705 6.29904L18.5705 3.70096L1.25 13.701L2.75 16.299Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="ml-2 mb:text-xl">Select</div>
            </div>
          </div>

          <div className="mt-4 w-1/2 flex justify-center items-center">
            <img src={image} alt="" className="w-36 max-md:w-32" />
          </div>
        </div>
      </Link>
    </>
  );
};
export default Main;
