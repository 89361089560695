import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Nav from "../components/detailedNav";
import image23 from "../resources/home/image23.png";
import image24 from "../resources/home/image24.png";
import image25 from "../resources/home/image25.png";
import image26 from "../resources/home/image26.png";
import image27 from "../resources/home/image27.png";
import image28 from "../resources/home/image28.png";
import image29 from "../resources/home/image29.png";
import image30 from "../resources/home/image30.png";
import image31 from "../resources/home/image31.png";
import image32 from "../resources/home/image32.png";
import Bg2 from "../resources/detailedHome/Bg2.png";
import Footer from "../components/detailedFooter";
import Dropdown from "../components/dropdown";
import UploadButton from "../components/fileUploaderButton";
import image1 from "../resources/admin/image41.png";
import image2 from "../resources/admin/image35.png";
import image3 from "../resources/admin/Group.png";
import image4 from "../resources/admin/image42.png";
import image5 from "../resources/admin/image41(1).png";
import image6 from "../resources/admin/excel_img.png";
import image7 from "../resources/admin/csv_img.png";
import image8 from "../resources/admin/image41_2.png";
import image9 from "../resources/admin/Group1905.png";
import Dropdown2 from "../components/admin/dropdown"
const Main = () => {
  const [headerData, setHeaderData] = useState("Default");
  const bib = useParams();
  console.log(bib.heading);
  // setHeaderData(bib.heading)
  const cleanHeading = (heading) =>
    heading.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, "-");

  const dummyData = [
    {
      image: image23,
      heading: "Production/ Manufacturing",
    },
    {
      image: image24,
      heading: "Supply Chain Management",
    },
    {
      image: image25,
      heading: "Research and Development",
    },
    {
      image: image26,
      heading: "Marketing and Brand Management",
    },
    {
      image: image27,
      heading: "Sales",
    },
    {
      image: image28,
      heading: "Human Resources (HR)",
    },
    {
      image: image29,
      heading: "Procurement",
    },
    {
      image: image30,
      heading: "Information Technology (IT)",
    },
    {
      image: image31,
      heading: "Legal and Compliance",
    },
    {
      image: image32,
      heading: "Finance",
    },
  ];
  useEffect(() => {
    dummyData.forEach((item) => {
      if (cleanHeading(item.heading) == bib.heading) {
        setHeaderData(item.heading); // Set the matching heading
      }
    });
  }, [bib.heading]);

  //below for 1st upload button
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    console.log("Selected file:", file);

    if (file) {
      // Create a new FormData instance and append the file
      const formData = new FormData();
      formData.append("file", file);

      localStorage.setItem("upload1", formData);
    }
  };

  // below for 2nd uplaod button
  const fileInputRef2 = useRef(null);

  const handleButtonClick2 = () => {
    fileInputRef.current.click();
  };

  const handleFileChange2 = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    console.log("Selected file:", file);

    if (file) {
      // Create a new FormData instance and append the file
      const formData = new FormData();
      formData.append("file", file);

      localStorage.setItem("upload2", formData);

      alert(`Successfully stored in localstorage.`);
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleRadioChange = (event) => {
    setDataRefresh((prevDataRefresh) =>
      prevDataRefresh.map((item) =>
        item.section ? { ...item, selectedOption: event.target.value } : item
      )
    );
  };

  //below for tab above dataRefresh radio selection box
  const [dataRefresh, setDataRefresh] = useState([
    {
      sectionName: "your data",
      section: true,
      selectedOption: "none",
    },
    {
      sectionName: "competitor data",
      section: false,
      selectedOption: "none",
    },
  ]);

  const handleTabYourDataCompData = (index) => {
    setSelectedIndex(index);
    const updatedDataRefresh = dataRefresh.map((data, i) => ({
      ...data,
      section: i === index, // Make only the clicked section true
    }));

    setDataRefresh(updatedDataRefresh);
  };
  const radioData = [image1, image2, image3, image4, image5, image8];


  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
  }, []);

  const dropDownData2=["PDF","PPT"]
  const dropDownHandle=()=>{

  }
  return (
    <>
      {/* <h1>DETAILED</h1> */}
      <div
        className="flex justify-center"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        <div
          className="w-full max-w-screen-2xl"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          <Nav prop={headerData} />
          <div
            className="bg-cover bg-center  min-h-screen bg-[#095458]"
            // style={{ backgroundImage: `url(${Bg})` }}
          >
            <div className="flex justify-center pt-5">
              <div className="w-[94%] sm:w-2/3 lg:w-2/3 max-lg:mx-auto  max-lg:mt-10">
                <div className="flex lg:w-1/2 mx-auto">
                <button
                    className={`px-6 py-2 rounded-tl-md ${
                      dataRefresh[0].section ? "text-white" : "text-[#121212]"
                    } text-sm ${
                      dataRefresh[0].section ? "bg-[#095458]" : "bg-white"
                    } w-[40%] ml-auto font-semibold border ${
                      dataRefresh[0].section
                        ? "border-black"
                        : "border-gray-300"
                    } border-b-0`}
                    style={{
                      boxShadow: dataRefresh[0].section
                        ? "0px 0px 5px black"
                        : "none",
                    }}
                    onClick={() => handleTabYourDataCompData(0)}
                  >
                    Your Data
                  </button>
                  <button
                    className={`px-2 py-2 rounded-tr-md ${
                      dataRefresh[1].section ? "text-white" : "text-[#121212]"
                    } text-sm  ${
                      dataRefresh[1].section ? "bg-[#095458]" : "bg-white"
                    } font-semibold border ${
                      dataRefresh[1].section
                        ? "border-black"
                        : "border-gray-300"
                    } w-[40%] mr-auto ${
                      dataRefresh[1].section ? "border-b-0" : ""
                    }`}
                    style={{
                      boxShadow: dataRefresh[1].section
                        ? "0px 0px 5px black"
                        : "none",
                    }}
                    onClick={() => handleTabYourDataCompData(1)}
                  >
                    Competitor Data
                  </button>
                </div>

                <div
                  className="py-10 px-8 bg-[#008085] rounded-2xl   mb-10 "
                  style={{ boxShadow: "0px 2px 3px black" }}
                >
                  <div>
                    <h2 className="text-xl text-white mb-3 font-bold">
                      Data Source
                    </h2>
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
                      {radioData.map((data, ind) => {
                        return (
                          <div key={ind} className="flex items-center mb-2">
                            <input
                              type="radio"
                              id={`option${ind}`}
                              name="data-source"
                              className="mr-2 custom-radio"
                              value={`option${ind}`}
                              onChange={handleRadioChange}
                              checked={
                                dataRefresh[selectedIndex]?.selectedOption ==
                                `option${ind}`
                              }
                            />

                            <label htmlFor={`option${ind}`} className="w-full">
                              <div className="h-[44px] w-full bg-gray-100 rounded-lg flex justify-center items-center">
                                <img
                                  src={data}
                                  alt=""
                                  draggable="false"
                                  className="h-[44px] w-[154px] bg-gray-100 rounded-lg object-contain px-3 py-1 cursor-pointer"
                                />
                              </div>
                            </label>
                          </div>
                        );
                      })}

                      <div className="flex items-center mb-2">
                        <input
                          type="radio"
                          id="option6"
                          name="data-source"
                          className="mr-2 custom-radio"
                          value="option6"
                          key={6}
                          onChange={handleRadioChange}
                          checked={
                            dataRefresh[selectedIndex]?.selectedOption ==
                            `option6`
                          }
                        />
                        <label htmlFor="option6" className="w-full">
                          <div className="h-[44px] w-full bg-gray-100 rounded-lg flex justify-center items-center">
                            <img
                              src={image6}
                              alt=""
                              draggable="false"
                              className="h-[24px] w-[64px] object-contain cursor-pointer"
                            />
                          </div>
                        </label>
                      </div>

                      <div className="flex items-center mb-2">
                        <input
                          type="radio"
                          id="option7"
                          name="data-source"
                          className="mr-2 custom-radio"
                          value="option7"
                          key={7}
                          onChange={handleRadioChange}
                          checked={
                            dataRefresh[selectedIndex]?.selectedOption ==
                            `option7`
                          }
                        />
                        <label htmlFor="option7" className="w-full">
                          <div className="h-[44px]  bg-gray-100 rounded-lg flex justify-center items-center cursor-pointer">
                            <img
                              src={image7}
                              alt=""
                              draggable="false"
                              className="h-[24px] w-[64px] object-contain"
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {dataRefresh[selectedIndex].selectedOption != "none" &&
                    dataRefresh[selectedIndex].selectedOption !== "option6" &&
                    dataRefresh[selectedIndex].selectedOption !== "option7" && (
                      <div className="lg:w-1/3 mx-auto mt-5">
                        <h3 className="text-sm text-white my-3 font-bold">
                          API
                        </h3>
                        <input
                          type="text"
                          className="w-full p-3 text-xs rounded-md"
                          placeholder="Enter API key"
                        />
                      </div>
                    )}

                  {dataRefresh[selectedIndex].selectedOption != "none" &&
                    (dataRefresh[selectedIndex].selectedOption === "option6" ||
                      dataRefresh[selectedIndex].selectedOption ===
                        "option7") && (
                      <div className="mt-5 lg:w-1/3 mx-auto">
                        <div>
                          <input
                            type="file"
                            id="fileUploader"
                            name="file"
                            className="hidden"
                            accept={
                              dataRefresh[selectedIndex].selectedOption ===
                              "option6"
                                ? ".xlsx, .xls" // Allow Excel files
                                : dataRefresh[selectedIndex].selectedOption ===
                                  "option7"
                                ? ".csv" // Allow CSV files
                                : ""
                            }
                          />
                          <label htmlFor="fileUploader">
                            <img
                              src={image9}
                              alt="Upload file"
                              draggable="false"
                            />
                          </label>
                        </div>
                      </div>
                    )}

                  <button
                    className="bg-[#00acc1] w-full mt-8 py-2 text-white font-bold flex justify-center rounded-md lg:w-1/3 mx-auto"
                    style={{ boxShadow: "2px 2px 3px #00000040" }}
                  >
                    <span className="ml-3">SUBMIT</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="text-sm flex items-center justify-center text-white mt-6  ">
              <div
                className="w-2/3 max-sm:w-full max-sm:px-2 text-center max-sm:text-xs"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                As a user, you are responsible for harmonizing your dataset and
                the competitor dataset. This involves ensuring that both
                datasets have compatible structures, formats, and values. Proper
                harmonization is vital for generating accurate and insightful
                analyses.
              </div>
            </div>
            <div
              className="flex justify-center mt-14"
              style={{
                backgroundImage: `url(${Bg2})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="bg-red-300 w-[66%] aspect-square flex flex-wrap border-8 rounded-md border-gray-500 overflow-hidden">
                <div className="h-[50%] w-[50%] bg-black"></div>
                <div className="h-[50%] w-[50%] bg-red-700"></div>
                <div className="h-[50%] w-[50%] bg-green-700"></div>
                <div className="h-[50%] w-[50%] bg-blue-700"></div>
              </div>
            </div>

            <div className="flex justify-center py-20 bg-white">
              <div className="sm:flex  items-center">
                <div className="flex items-center justify-center">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 43 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.8125 30.9062H29.5625V33.5938H18.8125V30.9062ZM13.4375 30.9062H16.125V33.5938H13.4375V30.9062ZM18.8125 24.1875H29.5625V26.875H18.8125V24.1875ZM13.4375 24.1875H16.125V26.875H13.4375V24.1875ZM18.8125 17.4688H29.5625V20.1562H18.8125V17.4688ZM13.4375 17.4688H16.125V20.1562H13.4375V17.4688Z"
                      fill="#2D313D"
                    />
                    <path
                      d="M33.5938 6.71875H29.5625V5.375C29.5625 4.66223 29.2794 3.97865 28.7753 3.47465C28.2713 2.97065 27.5878 2.6875 26.875 2.6875H16.125C15.4122 2.6875 14.7287 2.97065 14.2247 3.47465C13.7206 3.97865 13.4375 4.66223 13.4375 5.375V6.71875H9.40625C8.69348 6.71875 8.0099 7.0019 7.5059 7.5059C7.0019 8.0099 6.71875 8.69348 6.71875 9.40625V37.625C6.71875 38.3378 7.0019 39.0213 7.5059 39.5253C8.0099 40.0294 8.69348 40.3125 9.40625 40.3125H33.5938C34.3065 40.3125 34.9901 40.0294 35.4941 39.5253C35.9981 39.0213 36.2812 38.3378 36.2812 37.625V9.40625C36.2812 8.69348 35.9981 8.0099 35.4941 7.5059C34.9901 7.0019 34.3065 6.71875 33.5938 6.71875ZM16.125 5.375H26.875V10.75H16.125V5.375ZM33.5938 37.625H9.40625V9.40625H13.4375V13.4375H29.5625V9.40625H33.5938V37.625Z"
                      fill="#2D313D"
                    />
                  </svg>

                  <div
                    className="font-bold mx-3"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Download Results
                  </div>
                </div>
                <div className="mx-3 max-sm:mt-5">
                  <div className="flex">
                  <Dropdown2
                        data={dropDownData2}
                        dropdownHandle1={dropDownHandle}
                      />
                    <UploadButton prop="Download" />
                  </div>
                </div>
              </div>
            </div>
            {/* Red overlay div */}
          </div>

          <Footer />
          {/* <div>
          <div className="h-[500px] bg-[#095458]">

          </div>
          <div className="h-[500px] bg-white">

          </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default Main;
