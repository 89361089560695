import Home from "./decisivePulseAI/pages/Home"
import { Routes,Route } from "react-router-dom";
import Login from './decisivePulseAI/pages/loginVar4'
// import './style.css'
import './index.css'
import DetailedHomeDecisionAI from './decisivePulseAI/pages/detailedHomeCard'
import Admin from './decisivePulseAI/pages/admin'
import About from './decisivePulseAI/pages/about'
import Signup from './decisivePulseAI/pages/signup'
const App=()=> {
  return (
    <>
    

    <Routes>
        <Route path="/home" element={<Home/>}></Route>
        <Route
          path="/home/:heading"
          element={<DetailedHomeDecisionAI />}
        ></Route>
        <Route path="/" element={<Login/>}></Route>
        <Route path="/signup" element={<Signup/>}></Route>
        
        
        <Route path="/admin" element={<Admin/>}></Route>
        <Route path="/about-decision-pulse-ai" element={<About/>}></Route>
        

    </Routes>


    </>
  );
}

export default App;
