import React, { useState, useEffect, useRef } from "react";
import Clock from "../../resources/admin/Vector2.png";

const Dropdown = ({ data, dropdownHandle1 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select Timezone");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left w-full" ref={dropdownRef}>
      <div className="max-lg:w-full">
        {/* Dropdown button */}
        <button
          id="dropdownButton"
          className="w-full text-sm rounded-md border border-black shadow-sm max-sm:py-3.5 py-3 pr-10 bg-white font-medium text-gray-700 hover:bg-gray-50"
          style={{
            backgroundImage: `url(${Clock})`,
            backgroundPosition: "right 13px center",
            backgroundRepeat: "no-repeat",
          }}
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
          <span className="max-sm:text-xs flex justify-start pl-2">
            {window.innerWidth <= 525
              ? selectedOption.length > 20
                ? `${selectedOption.slice(0, 20)}...`
                : selectedOption
              : selectedOption.length > 30
              ? `${selectedOption.slice(0, 30)}...`
              : selectedOption}
          </span>
        </button>
      </div>

      {/* Dropdown menu */}
      {isOpen && (
        <div
          id="dropdownMenu"
          className="origin-top-right absolute left-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="dropdownButton"
        >
          <div className="h-[250px] lg:h-[150px]  overflow-scroll" role="none">
            <div className="w-full flex justify-center pt-3">
              <input
                type="text"
                placeholder="Search..."
                className="border border-gray-300 rounded-md w-[90%] px-4 py-2 text-sm text-gray-700 placeholder-gray-500 focus:outline-none focus:border-blue-500"
              />
            </div>

            <div className="font-bold text-base pl-5 pt-3">TIME ZONE</div>
            {data.map((option, index) => (
              <div key={index}>
                <span
                  onClick={() => handleOptionClick(option)}
                  className="block px-4 py-2 text-xs text-gray-700 hover:text-white cursor-pointer hover:bg-[#095458]"
                  role="menuitem"
                >
                  {option}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
